/* COLORS*/
/* :root {
  --primary-color: #fed8b1;
  --secondary-color: #ecb176;
  --tertiary-color: #a67b5b;
} */

/* :root {
  --primary-color: #eadbc8;
  --secondary-color: #c7b7a3;
  --tertiary-color: #eadbc8;
} */

/* :root {
  --primary-color: #fdaf7b;
  --secondary-color: #be7b72;
  --tertiary-color: #824d74;
} */

:root {
  --primary-color: #b2a59b;
  --secondary-color: #ded0b6;
  --tertiary-color: #be7b72;
}
