.imageContainer {
  padding: 0;
}

.innerContainer {
  max-width: none;
  height: 240px;
  position: relative;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tipsContainer {
  padding-bottom: 100px;
  background-color: var(--secondary-color);
  padding-left: 10px;
}

.tipsInner {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  padding: 0 20px 0 20px;
  max-width: 720px;
}

.header {
  text-align: left;
  font-size: 42px;
  font-family: Tiempos Headline, serif;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
}

@media (min-width: 900px) {
  .innerContainer {
    height: 420px;
  }
  .tipsContainer {
    margin: 0 auto;
    padding: 60px 40px;
  }

  .tipsInner {
    margin: 0 auto;
  }
}
