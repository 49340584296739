
.Wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 80px;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    // > * {
    //     opacity: 0;
    //     transform: translateY(55px);
    //     transition: all 0.4s;
    // }

    @media (max-width:760px) and (orientation:landscape) {
        min-height: 110vh;
        padding: 70px 20px;
    }

    @media (max-width:760) and (orientation:portrait) {
        min-height: 80vh;
        padding: 70px 20px;
    }
}

.bannerInput{
    min-height: 56px;
    color: #000;
    background: #fff;
    width: 200px;
    border: 1px solid #fff;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1.5;
    padding: 0 16px;
    display: block;
    font-weight: 400;
    outline: none;
    margin: 0 auto;
}

.textWrap {
    max-width: 900px;
    text-align: center;

    h1 {
        font-size: 4.7em;
        line-height: 1.1;
        color: $white;
        margin-bottom: 10px;
        text-shadow: 0 3px 5px rgba(0,0,0, .5);

        @media (max-width:$breakPint) {
            font-size: 2.5em;
        }
    }

    p {
        font-size: 1.2em;
        color: #fff;
    }

    .btn {
        font-size: 1.1em;
        font-weight: 600;
        color: #fff;
        background-color: $buttonColor;
        display: inline-block;
        padding: 10px 30px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.5s;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            letter-spacing: 4px;
        }
    }

    // > * {
    //     opacity: 1;
    //     transform: translateY(-45px);
    //     transition: all 1s;
    // }
}

