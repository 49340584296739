.footer {
  background-color: var(--primary-color);
  overflow: hidden;
}

.footerContainer {
  padding: 90px 40px 40px;
  max-width: 1300px;
  margin: 0 auto;
}

.footerSection {
  padding-bottom: 60px;
  border-bottom: 1px solid #d6d2c4;
  margin-bottom: 25px;
}

.footerSection h3 {
  margin-bottom: 10px;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  margin-top: -60px;
  margin-left: -40px;
  margin-bottom: 0;
  list-style-type: none;
}

.footerLinksListItem {
  padding-left: 25px;
  width: 50%;
  margin-top: 60px;
  white-space: nowrap;
}

.headerWrapper {
  display: inline-block;
}

.header {
  padding: 0;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: Tiempos Headline, serif;
  color: #5a5a5a;
  font-weight: 400;
}

.socialMediaSection {
  border-bottom: 1px solid #d6d2c4;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.footerSocial {
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  margin: -20px 0 0 -40px;
}

.footerLinksWrapper {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.footerLinkItem {
  margin-bottom: 20px;
}

.footerLink {
  font-size: 13px;
  color: #0c0d0f;
  padding: 10px 0;
  text-decoration: none;
}

.footerSocialIcon {
  width: 33.3333333333%;
  margin-top: 20px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
}

.footerSocialLink {
  display: block;
  width: 60px;
  text-align: center;
  background-color: transparent;
  text-decoration: none;
  color: #000;
}

.socialIcon {
  font-size: 40px;
  background-position: 50%;
  margin: 0 auto;
  color: #5a5a5a;
}

.infoSection {
  margin-bottom: 20px;
}

.footerInfoLinks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.footerInfoLink {
  display: inline-block;
  padding: 10px;
  white-space: nowrap;
  margin-left: 20px;
}

.footerLinkInfo {
  padding: 10px;
  margin: -10px;
  font-weight: 700;
  font-size: 13px;
  color: #5a5a5a;
  text-decoration: none;
}

.policy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -10px;
}

@media (min-width: 900px) {
  .header {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .footerLinks {
    justify-content: space-between;
  }

  .footerLinksListItem {
    width: 25%;
  }

  .footerLinkItem {
    margin-bottom: 30px;
  }

  .footerLink {
    font-size: 15px;
  }

  .footerSocial {
    max-width: 800px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .footerSocialIcon {
    width: auto;
    padding-left: 0;
  }

  .infoSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footerInfoLinks {
    width: auto;
    margin-bottom: 0;
    margin-left: -30px;
  }

  .footerInfoLink {
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
  }
}
